:global {
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    display: flex !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    justify-content: center;
    align-items: center;
  }
  .modal-fade {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1050;
    display: flex !important;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0;
    justify-content: center;
    align-items: center;
  }
  .modal.fade.show {
    display: flex !important;
    z-index: 1050;
    justify-content: center;
    align-items: center;
  }

  .modal-content {
    min-width: 300px;
    padding-top: 16px;
    padding-bottom: 24px;
    transition: width 20ms linear, height 200 linear;
  }

  body .whirl::before{
    background-color: transparent;
  }
}

.icon_modal {
  display: flex;
  text-align: center;
  justify-content: center;
  em{
    font-size: 96px;
    margin-top: -64px;
    color: #f05050 !important;
    background:white;
    border-radius: 50%;
  }
}
