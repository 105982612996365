/* ========================================================================
     Component: sidebar
 ========================================================================== */

$submenu-header-hg: 40px; // Submenu header when hover collapse sidebar
$sidebar-item-border-wd: 3px;
$scrollbar-wd: 17px;

// SIDEBAR

$sidebar-bg: rgb(44, 190, 203);

$sidebar-item-color: #ffffff;
$sidebar-item-color-active: rgb(235, 0, 139) !important;
$sidebar-item-bg-active: darken($sidebar-bg, 1%);

$sidebar-icon-color: inherits;
$sidebar-icon-color-active: $sidebar-item-color-active;

$sidebar-bullet-color: rgba(0, 0, 0, 0.25);
$sidebar-bullet-color-active: $sidebar-icon-color-active;

$sidebar-heading-color: #919da8;
$sidebar-label-color: $sidebar-item-color;

.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: $sidebar-bg;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; // draw a divider for the sidebar
  &:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px);
  }

  .nav-heading {
    padding: 12px 15px;
    color: $sidebar-heading-color;
    font-size: 13px;
    letter-spacing: 0.035em;
    pointer-events: none;
    cursor: default;
  }
}


// Items
// First main level
body:not(.aside-collapsed) nav > .sidebar-nav:not(.sidebar-subnav) {
  padding: 0 23px;
}
.sidebar-nav {
  position: relative;
  font-size: 14px;
  margin-bottom: 0;
  padding: 0;
  list-style: none; // Only the first item in sidebar
  > .nav-heading:first-child {
    padding-top: 20px;
  }

  > li {
    display: block;
    // border-left: $sidebar-item-border-wd solid transparent;
    @include transition(border-left-color 0.4s ease);
    &:hover {
      background-color: #6a6a6a;
    }
    transition: background-color 100ms ease, border-left-color 0.4s ease;

    > a,
    > .nav-item {
      position: relative;
      display: block;
      padding: 12px 4px 12px 8px;
      color: $sidebar-item-color;
      letter-spacing: 0.025em;
      font-weight: normal;
      cursor: pointer;
      border-radius: 5px;
      &:focus,
      &:hover {
        text-decoration: none;
        outline: none;
        color: $sidebar-item-color-active;
      } // Item icon
      > em {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: $sidebar-icon-color;
      }
    } // Active item state
    &.active,
    &.open {
      &,
      > a,
      > .nav-item,
      .sidebar-nav {
        background-color: $sidebar-item-bg-active;
        color: $sidebar-item-color-active;
      }
      > .nav-item > em,
      > a > em {
        color: $sidebar-icon-color-active;
      }
    }

    &.active {
      border-left: $sidebar-item-color-active;
    }
  }
  .badge {
    display: block;
  }
}

// Hides the scrollbar only on desktops
$tablet-landscape: 1025px;
@media only screen and (min-width: $tablet-landscape) {
  .sidebar:not(.show-scrollbar) {
    margin-right: -$scrollbar-wd;
    overflow-y: scroll;
  }
}

// Sub Levels
.sidebar-subnav {
  background-color: $sidebar-bg;

  > .sidebar-subnav-header {
    color: $sidebar-item-color;
    display: none;
    padding: 10px 20px;
    font-weight: bold;
  }

  > li {
    border-left: 0 !important;
    > a,
    > .nav-item {
      display: block;
      position: relative;
      padding: 10px 0px;
      padding-left: 30px;
      font-weight: normal;
      background-color: transparent !important;
      color: $sidebar-item-color;

      &:focus,
      &:hover {
        color: $sidebar-item-color-active;
        background-color: #e1e2e3;
      } // submenu icons
      > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em;
      }
    }

    &.active {
      > a,
      > .nav-item {
        color: $sidebar-icon-color-active;
        &:after {
          border-color: $sidebar-bullet-color-active;
          background-color: $sidebar-bullet-color-active;
        }
      }
    } // Nested levels
    .sidebar-nav > li {
      padding-left: 5px;
    }
  } // li
  // This only happend when sidebar is collapsed and the submenu
  // visibility is toggled via js
  &.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; // we resuse the collapse element so we need to override
    &,
    .collapse,
    .sidebar-subnav {
      height: auto !important;
      display: block !important;
      visibility: visible !important;
      &.opening {
        animation: none !important;
      }
    }
    > .sidebar-subnav-header {
      display: block;
    } // reduce padding when item is on sidebar collapsed
    li > a,
    li > .nav-item {
      padding-left: 20px; // submenu icons
      em {
        margin-left: 0;
      }
    }
  }
}

// Desktop styles
// ---------------------------------
@include media-breakpoint-up(md) {
  .sidebar > .sidebar-nav {
    .badge {
      margin: 2px 0 0 0;
    }
  }
} // @media @desktop
// Collapsed state styles
.aside-collapsed,
.aside-collapsed-text {
  .sidebar {
    overflow-x: hidden; // Applies to first level items
    > .sidebar-nav {
      .sidebar-nav,
      > .nav-heading,
      > li > .nav-item > span,
      > li > a > span {
        display: none !important;
      }

      > li {
        width: $aside-wd-collapsed - 1;
        > a,
        > .nav-item {
          text-indent: -$sidebar-item-border-wd;
          padding: 20px 0;
          text-align: center;
          > em {
            font-size: 1.6em; // increase icons size
            width: auto;
          }
          &:focus {
            // accessibility
            background-color: transparent;
            > em {
              color: inherit;
            }
          }
        }
      }
    } // labels in menu items
    .sidebar-nav {
      .badge {
        position: absolute;
        top: 10px;
        right: 5px;
        text-indent: 0;
      }
    }
  } // .sidebar
} // .aside-collapsed/text
.aside-collapsed-text {
  .sidebar {
    > .sidebar-nav {
      > li > .nav-item > span,
      > li > a > span {
        display: block !important;
        font-size: 12px;
      }

      > li {
        width: $aside-wd-collapsed-text - 2;
        > a {
          padding: 14px 0;
          > em {
            font-size: 1.4em;
          }
        }
      }
    }
  }
}

.sidebar-nav > li.active > .nav-item {
  background-color: rgb(235, 0, 139) !important;
  color: white !important;
  & > em {
    color: white !important;
  }
}

.sidebar-nav:not(.sidebar-subnav) > li.active > a {
  background-color: rgb(235, 0, 139) !important;
  color: white !important;
  & > em {
    color: white !important;
  }
}

.sidebar-subnav li.active.sub-subNav a,
.sidebar-subnav li.active.noChild {
  position: relative;
  // &::before {
  //   width: 12px;
  //   height: 12px;
  //   position: absolute;
  //   background: rgb(235, 0, 139);
  //   content: "";
  //   left: 24px;
  //   top: calc(50% - 6px);
  //   border-radius: 50%;
  // }

  .activeNav {
    background: #eb008b !important;
    padding: 3px 8px;
    color: white !important;
    border-radius: 5px;
  }
}
.wrp_nav {
  display: flex;
  a {
    width: 100%;
    color: white;
    text-decoration: none;
  }
  a:hover {
    color: #eb008b;
  }
}

.sidebar-nav.sidebar-subnav.subnav_hasChild {
  a {
    .activeNav {
      padding: 3px 8px;
    }
  }
  a:hover {
    .activeNav {
      background: white;
      padding: 3px 8px;
      color: #eb008b;
      border-radius: 5px;
    }
  }
}

.wrp_activeNav {
  padding: 0px;
  .activeNav {
    padding: 3px 8px;
  }
}

ul.nav-floating {
  .sidebar-nav > li.active:not(.noChild):hover {
    background: white !important;
  }
  .wrp_activeNav {
    padding: 10px 6px;
    .activeNav {
      padding: 3px 5px;
    }
  }
}

.aside-collapsed .sidebar > .sidebar-nav > li > .nav-item {
  padding: 15px 0;
}

.colapseMenu {
  float: right;
  line-height: 24px !important;
}

body.aside-collapsed {
  .colapseMenu {
    display: none !important;
  }
  .sidebar-subnav > li.active > a::before {
    left: 4px;
  }
}

.sidebar-nav.sidebar-subnav.nav-floating .sidebar-subnav-header {
  background: rgb(235, 0, 139) !important;
  color: white;
  width: calc(100% - 38px);
  margin-left: 20px;
  padding: 5px;
  margin-top: 10px;
  border-radius: 5px;
  position: relative;
  &::after {
    content: "\f107";
    position: absolute;
    right: 8px;
    font-family: "Font Awesome 5 Free";
  }
}

ul:not(.nav-floating) .sidebar-subnav > li.sub-subNav {
  padding-left: 20px;
  a {
    padding-left: 22px;
  }
}

.sidebar-nav.sidebar-subnav.nav-floating {
  li > a {
    padding-right: 18px;
    padding-left: 35px;
  }

  .nav-item {
    padding: 10px 18px 10px 36px;
    .subNav_navItem_hasChild {
      padding: 4px 6px;
    }
  }

  li.active > .nav-item {
    background-color: transparent !important;
    .subNav_navItem_hasChild {
      background: #eb008b !important;
      color: white !important;
      border: 5px;
      em.colapseMenu.fa.fa-angle-up {
        display: block !important;
        &::before {
          content: "\f105";
        }
      }
    }
  }

  .nav-item {
    em.fa.fa-angle-down {
      display: block !important;
      &::before {
        content: "\f105";
      }
    }
  }
  .nav-item + .collapse {
    display: none !important;
  }
  .nav-item:hover + .collapse,
  .nav-item + .collapse:hover {
    position: fixed;
    left: 260px;
    z-index: 1;
    margin-top: -41px;
    height: calc(100%) !important;
    display: block !important;

    ul.sidebar-nav.sidebar-subnav {
      padding-top: 12px;
      padding-bottom: 152px;
      width: 188px;
      height: calc(100%) !important;
      overflow-y: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
}

.sidebar-nav > li.active > a,
.sidebar-nav > li.active > a:hover,
.sidebar-nav > li.active > .nav-item {
  color: white;
}

.sidebar-nav > ul:not(.nav-floating) li.active.noChild > a {
  padding-left: 50px;
}

// .sidebar-nav > li.active:not(.noChild):hover,
// .sidebar-nav ul.subnav_hasChild li.active {
//   border-left: 3px solid #eb008b !important;
// }
// li.active ul.subnav_hasChild li {
//   border-left: 3px solid #eb008b !important;
// }

.sidebar-nav ul.subnav_hasChild {
  li {
    .active {
      li.sub-subNav:hover {
        background: transparent !important;
      }
    }
    li.sub-subNav {
      a {
        position: relative;
        padding-left: 30px;
        padding-right: 0px;
        padding-top: 10px;
        padding-bottom: 10px;
      }
    }
  }
}

// li.sub-subNav:hover {
//   background: white !important;
// }
.sidebar-nav ul.subnav_hasChild li.active .nav-item {
  background: transparent !important;
  padding-left: 27px;
  padding-right: 0px;
  .subNav_navItem_hasChild {
    background: #eb008b !important;
    padding: 8px 8px;
    color: white;
    border-radius: 5px;
  }
}

.sidebar-nav > li.active .sub-subNav:not(.active):hover {
  background: transparent;
  a:hover .activeNav {
    background: white;
    color: #eb008b;
    border-radius: 5px;
  }
}

.sidebar-nav > li.active:not(.noChild):hover,
.sidebar-nav > li.active:not(.noChild) {
  background: transparent;
}

.sidebar-nav > li:not(.active):hover,
.sidebar-nav > li.active.noChild:hover {
  background: transparent;
  color: white;
  .subNav_navItem_hasChild:hover {
    background: rgba(255, 255, 255, 0.9);
    color: rgb(235, 0, 139);
    border-radius: 5px;
  }

  .nav-item:hover {
    background: rgba(255, 255, 255, 0.9);
    color: rgb(235, 0, 139);
    border-radius: 5px;
  }
}

.sidebar-nav > li:hover > a {
  color: rgb(235, 0, 139);
}

.sidebar-nav > li:hover > a,
.sidebar-nav > li:hover > .nav-item {
  color: rgb(235, 0, 139);
  .subNav_navItem_hasChild {
    background: white;
    border-radius: 5px;
  }
}

.sidebar-nav > li.active > ul > li.active:hover > a,
.sidebar-nav > li.active > ul > li.active:hover > .nav-item {
  color: rgb(235, 0, 139);
}

.sidebar-nav li.active .subnav_hasChild li:active .nav-item {
  background: transparent !important;
}

.sidebar-nav ul li .nav-item .subNav_navItem_hasChild {
  padding: 8px 8px;
}

.sidebar-subnav.nav-floating > li > .nav-item {
  padding: 10px 20px;
  border-radius: 0px;
}

.sidebar-nav:not(.sidebar-subnav) > li > a,
.sidebar-nav:not(.sidebar-subnav) > li > .nav-item {
  margin-top: 8px;
}

.sidebar-nav > li:hover > a,
.sidebar-nav > li:hover > .nav-item {
  background: white;
}

.sidebar-nav > li > .nav-item.nav-item-heading > em:last-child{
  width: auto;
  margin-right: 5px;
}
